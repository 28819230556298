import langEN, { LangConfig } from './en'
import langTH from "@/components/provider/LangProvider/th";

function slotLang(str: string) {
    return function (slots: any[]): string {
        let res = str
        slots.forEach(slot => {
            res = res.replace(/\{(\w+)\}/i, slot)
        })
        return res
    }
}

const langEsData : Partial<LangConfig> = {
    "Nav_Wallet_Connect": "Iniciar sesión",
    "Nav_Wallet_Disconnect": "Cerrar sesión",
    "Nav_Badge_Page": "Insignia",
    "Nav_Event_Page": "Evento",
    "Wallet_Title_MetaMask": "MetaMask",
    "Wallet_Intro_MetaMask": "Conéctate a tu billetera MetaMask o billetera inyectada del explorador",
    "Wallet_Title_WalletConnect": "WalletConnect",
    "Wallet_Intro_WalletConnect": "Conéctate a tu billetera WalletConnect",
    "Wallet_Intro":  slotLang("Conéctate a {wallet}"),
    "UserAction_MyProfile": "Mi perfil",
    "UserAction_Disconnect": "Cerrar sesión",
    "Regist_Step_One_Title": "Configura un nombre de usuario",
    "Regist_Step_One_Des": "Por favor, ingresa caracteres en inglés o chino",
    "Regist_Step_One_Placeholder": "tu nombre de usuario",
    "Regist_Step_Two_Address_Title": "Vincula tu dirección de billetera",
    "Regist_Step_Two_Address_Des": "Por favor, ingresa tu dirección de billetera",
    "Regist_Step_Two_Address_Placeholder": "tu dirección de billetera",
    "Regist_Step_Two_Email_Title": "Vincula tu correo electrónico",
    "Regist_Step_Two_Email_Des": "Por favor, ingresa tu correo electrónico",
    "Regist_Step_Two_Email_Placeholder": "tu correo electrónico",
    "Regist_Step_Next": "Siguiente",
    "Regist_Title": "Configura un nombre de usuario único para Social Layer",
    "Domain_Rule": "El nombre de usuario puede contener letras en inglés de la a a la z y dígitos del 0 al 9. También se pueden usar guiones, pero no se puede usar al principio ni al final del nombre de usuario. Debe tener más de 6 caracteres.",
    "Regist_Input_Placeholder": "Tu nombre de usuario",
    "Regist_Input_Validate": slotLang("No puede exceder los {n} caracteres"),
    "Regist_Input_Validate_2": slotLang("No puede tener menos de {n} caracteres"),
    "Regist_Input_Validate_3": "El nombre de usuario contiene caracteres no válidos",
    "Regist_Input_Validate_4": slotLang("El dominio contiene un carácter no válido: {n}"),
    "Regist_Input_Error_Start": "El nombre de usuario no puede comenzar con \"-\"",
    "Regist_Input_Error_End": "El nombre de usuario no puede terminar con \"-\"",
    "Regist_Input_Empty": "Este campo no puede estar vacío",
    "Regist_Confirm": "Confirmar",
    "Regist_Dialog_Title": "Tu perfil será creado con este nombre de usuario: ",
    "Regist_Dialog_ModifyIt": "Editar",
    "Regist_Dialog_Create": "Crear",
    "Regist_InUse": "Este nombre de usuario ya está en uso",
    "Profile_User_NotExist": "El usuario no existe",
    "Profile_User_MindBadge": "Enviar una insignia",
    "Profile_User_IssueBadge": "Enviar una insignia a ",
    "Profile_User_Qrcode_download": "Descargar",
    "Profile_Tab_Received": "Insignia",
    "Profile_Tab_Basic": "Básico",
    "Profile_Tab_NFTPASS": "NFT Pass",
    "Profile_Tab_Minted": "Creado",
    "Profile_Tab_Point": "Punto",
    "Profile_Tab_Groups": "Grupos",
    "Profile_Tab_Presend": "Enviando",
    "Profile_Show_Wallet": "Tu dirección es",
    "Profile_Show_Email": "Tu correo electrónico es",
    "Profile_Show_Close": "Cerrar",
    "Profile_Show_Copy": "Copiar",
    "Avatar_Upload_Button": "Subir",
    "BadgeDialog_Btn_Login": "Iniciar sesión para recibir",
    "BadgeDialog_Btn_Reject": "Rechazar",
    "BadgeDialog_Btn_Accept": "Aceptar",
    "BadgeDialog_Btn_Face2face": "Código QR",
    "BadgeDialog_Btn_share": "Enviar",
    "BadgeDialog_Btn_Issue": "Enviar de nuevo",
    "BadgeDialog_Btn_None_Left": "Qué pena",
    "BadgeDialog_Label_Creator": "Creador",
    "BadgeDialog_Label_Token": "Dominio de insignia",
    "BadgeDialog_Label_Issuees": "Receptor(es)",
    "BadgeDialog_Label_action_hide": "Establecer como privado",
    "BadgeDialog_Label_action_public": "Establecer como público",
    "BadgeDialog_Label_action_top": "Establecer en la parte superior",
    "BadgeDialog_Label_action_untop": "Eliminar de la parte superior",
    "BadgeDialog_Label_hide_tip": "Solo visible para ti",
    "BadgeDialog_Label_Creat_Time": "Hora de creación",
    "BadgeDialog_Label_Private": "Tipo de Insignia",
    "BadgeDialog_Label_Private_text": "Privada",
    "BadgeDialog_Label_gift_text": "Tarjeta de regalo",
    "BadgeletDialog_title": "Detalles de la Insignia",
    "BadgeletDialog_presend_title": "Detalles del Envío",
    "BadgeletDialog_invite_title": "Detalles de la Invitación",
    "BadgeletDialog_gift_title": "Detalles de la Tarjeta de Regalo",
    "BadgeletDialog_Reason": "Motivo",
    "MintBadge_Title": "Enviar una insignia",
    "MintBadge_Upload": "Imagen de la Insignia",
    "MintBadge_UploadTip": slotLang("Soporta JPG, GIF, PNG. Tamaño máximo de {size}"),
    "MintBadge_Name_Label": "Nombre de la Insignia",
    "MintBadge_Name_Placeholder": "Nombrar tu insignia",
    "MintBadge_Domain_Label": "Dominio de la Insignia",
    "MintBadge_Domain_Placeholder": "Dominio",
    "MintBadge_Submit": "Enviar a un amigo",
    "MintBadge_Next": "Siguiente",
    "MintBadge_Submit_To": slotLang("Enviar a {1}"),
    "MintBadge_Submiting": "Enviando",
    "MintBadge_Domain_Rule": "El dominio es el identificador único para tu insignia. <br /> Los dominios pueden contener letras en inglés de la a a la z y dígitos del 0 al 9. También se pueden usar guiones, pero no se pueden usar al principio ni al final del nombre del dominio. Debe tener más de 4 caracteres.",
    "MintFinish_Title": "¡Envío Finalizado!",
    "MintFinish_Button_Later": "Enviar más tarde",
    "MintFinish_Button_Issue": "Ir a Enviar",
    "IssueBadge_Title": "Enviar una insignia",
    "IssueBadge_Domain": "Dominio de la Insignia",
    "IssueBadge_Reason": "Motivo (Opcional)",
    "IssueBadge_Create_time": "Creado",
    "IssueBadge_ReasonPlaceholder": "Motivo de emisión",
    "IssueBadge_Issuees": "receptor(es)",
    "IssueBadge_Support": "Soporte <br />1. dirección de billetera; <br/>2. dominio que termine en '.dot/.eth'; <br />3. nombre de usuario de Social Layer o dominio de usuario",
    "IssueBadge_IssueesPlaceholder": "Ingresa el dominio o la dirección de billetera del receptor",
    "IssueBadge_GoToIssue": "Ir a Enviar",
    "IssueBadge_Issuesing": "Enviando",
    "IssueBadge_Mint": "Enviar",
    "IssueBadge_Mint_later": "Enviar más tarde",
    "IssueBadge_Sendwithlink": "Por enlace",
    "IssueBadge_By_QRcode": "Por Código QR",
    "IssueBadge_Sendwithdomain": "Por nombre",
    "IssueBadge_linkbtn": "Enlace",
    "IssueBadge_Eventbtn": "Evento",
    "IssueBadge_Address_List_Title": "Seleccionar receptores",
    "IssueBadge_Input_Error": "Dominio, dirección de billetera o nombre de usuario no válidos.",
    "IssueBadge_Input_Des": "Ingresa el dominio/dirección de billetera/correo electrónico de la insignia para que el receptor pueda recibirla.",
    "IssueFinish_Title": "Compartir",
    "IssueFinish_Share_By_Qrcode": "Compartir por código QR",
    "IssueFinish_Share_By_Link": "Compartir por enlace",
    "IssueFinish_CopyLink": "Copiar enlace",
    "IssueFinish_Screenshot": "Por favor, toma una captura de pantalla para compartir",
    "IssueFinish_Screenshot_Or": "o",
    "IssueFinish_share": "#1 te ha enviado una insignia NFT: #2. ¡Ve a obtenerla! \n Se recomienda usar el navegador MetaMask o imToken para acceder al sitio web.\n #3",
    "IssueFinish_Share_Card_text_1": "te ha enviado una insignia",
    "IssueFinish_Share_Card_text_2": "Enviado por @Social Layer",
    "Search_Cancel": "Cancelar",
    "Search_Label_Domain": slotLang("Dominio para \"{keyword}\":"),
    "Search_Label_Badge": slotLang("Insignias para \"{keyword}\":"),
    "Home_SubTitle": "El honor social de tu vida",
    "Home_Content": "Cada POAP es un regalo de un emisor a los coleccionistas, en celebración de un recuerdo especial compartido.<br>Al acuñar estos recuerdos en la blockchain, los coleccionistas construyen un rico tapiz de experiencias tokenizadas que desbloquea un mundo de posibilidades.",
    "Home_SearchPlaceholder": "Buscar Billetera/Dominio",
    "Home_ButtonLabel": "Explorar mi colección",
    "Home_ButtonTip": "Conectar Billetera",
    "Copied": "Copiado",
    "Landing_Title": "Bienvenido a <span>Social Layer 🎉</span>",
    "Landing_Sub_Tittle_1": "¿Qué es Social Layer?",
    "Landing_Sub_Tittle_2": "¿Qué puedes hacer?",
    "Landing_Sub_Tittle_3": "¿Cómo enviar una insignia?",
    "Landing_Des_1": "Una dApp donde cualquiera puede enviar insignias a cualquier persona, construyendo la identidad digital de una persona basada en valor subjetivo e inquantificable. La emisión de insignias no está sujeta a revisión o aprobación de terceros, y las insignias más valiosas surgirán naturalmente en la comunidad multivalorada.",
    "Landing_Des_2": "Expresa tus sentimientos hacia los demás otorgando insignias y descubre a más personas afines a través de las insignias.",
    "Landing_Des_3": "Haz clic en 'Comenzar' para crear tu propia identidad en cadena. Ve a la página de perfil y otorga insignias. Como usuarios tempranos, Social Layer pagará las tarifas de gas por ti.",
    "Landing_Des_4": "Para más información:",
    "Landing_White_Paper": "Whitepaper de Social Layer",
    "Landing_Button": "Comenzar",
    "Landing_Badge_Receive": "Inicia sesión para recibir",
    "WhatsApp_Share": slotLang("{domain} te envió una insignia NFT: {badge}. ¡Ve a obtenerla! {url}"),
    "Login_Title": "Iniciar sesión con Correo Electrónico",
    "Login_alert": "Por favor ingresa una dirección de correo electrónico",
    "Login_solar": "Iniciar sesión con Social Layer",
    "Login_continue": "Continuar",
    "Login_Placeholder": "Tu Correo Electrónico",
    "Login_option": "O más opciones",
    "Login_input_Code_title": "Revisa tu bandeja de entrada",
    "Login_input_Code_des": slotLang("Ingresa el código que te enviamos a {email} para completar la configuración de tu cuenta."),
    "Page_Back": "Atrás",
    "Page_Back_Done": "Hecho",
    "Picture_Recommend_Title": "Ejemplo",
    "Picture_Recommend_Create_By_Canva": "Creado con Canva",
    "Picture_Recommend_Download_A_Template": "Descargar una plantilla",
    "Quantity_input_label": "Cantidad",
    "Quantity_Unlimited": "Ilimitado",
    "Presend_step": "Rellena la cantidad de insignias. <br /> Las insignias se enviarán a los receptores como un enlace.",
    "presend_share_link": "#1 te ha enviado una insignia NFT: #2. ¡Ve a obtenerla! \n #3 \n Se recomienda usar el navegador MetaMask o imToken para acceder al sitio web.",
    "Activity_Badge": "Insignias de Grupo",
    "Activity_Calendar": "Calendario de Eventos",
    "Activity_Page_type": "Actividad",
    "Activity_State_Registered": "participando",
    "Activity_State_Created": "Creado",
    "Activity_Online_Event": "Evento en Línea",
    "Activity_Max_Participations": "Hasta #1 participaciones",
    "Activity_login_title": "¡No hay eventos registrados aún!",
    "Activity_login_des": "Inicia sesión para participar en un evento divertido",
    "Activity_login_btn": "Iniciar sesión / Registrarse",
    "Activity_search_placeholder": "Buscar eventos…",
    "Activity_no_activity": "No hay actividades aún～",
    "Activity_latest": "Último Evento",
    "Activity_Commended": "Recomendado",
    "Activity_Popular": "Popular",
    "Activity_Past": "Pasado",
    "Activity_Coming": "Próximos",
    "Activity_Greeting_Morning": "Buenos Días",
    "Activity_Greeting_Afternoon": "Buenas Tardes",
    "Activity_Greeting_Evening": "Buenas Noches",
    "Activity_My_Register": "Mi registro",
    "Activity_My_Event": "Mis Eventos",
    "Activity_All_Activity": "Todos los Eventos",
    "Activity_Btn_Create": "Crear un Evento",
    "Activity_Btn_Modify": "Modificar Evento",
    "Activity_Create_title": "Crear un Evento",
    "Activity_Create_Btn": "Crear un Evento",
    "Activity_Setting_Btn": "Configuración",
    "Activity_Setting_title": "Configuración del Evento",
    "Activity_Create_Done": "Hecho",
    "Activity_Create_Success": "Creación Exitosa 🎉",
    "Activity_Create_Success_Scan_tips": "Escanea el código <br> y asiste al evento",
    "Activity_Create_Success_Scan_tips_2": "| Actividad",
    "Activity_Scan_checkin": "Escanea el código QR para registrarte",
    "Activity_Scan_punch_in": "Escanea el código QR para fichar",
    "Activity_Registered_participants": "Participantes registrados",
    "Activity_originators": "Anfitrión",
    "Activity_Des": "Contenido de la actividad",
    "Activity_Participants": "Participantes",
    "Activity_Punch_Log": "Registro de Fichajes",
    "Activity_Punch_in_BTN": "Fichar",
    "Activity_Cancel_registration": "Cancelar Registro",
    "Activity_Form_Cover": "Portada/Poster (Opcional)",
    "Activity_Form_Checklog": "Configurar como ubicación de fichaje",
    "Activity_Form_Name": "Nombre del Evento",
    "Activity_Form_Details": "Descripción del Evento (Opcional)",
    "Activity_Form_online_address": "URL de la Reunión (Opcional)",
    "Activity_Form_Starttime": "¿Cuándo ocurrirá?",
    "Activity_Form_Ending": "¿Cuándo finalizará?",
    "Activity_Form_Where": "¿Dónde se llevará a cabo el evento?",
    "Activity_Form_participants": "Participantes máximos",
    "Activity_Form_participants_Min": "Participantes mínimos",
    "Activity_Form_Guest": "Invitar a invitados al evento (Opcional)",
    "Activity_Form_Duration": "Establecer Duración",
    "Activity_Form_Duration_Cancel": "Cancelar duración establecida",
    "Activity_Form_Hoster": "Anfitrión",
    "Activity_Form_Label": "Etiquetas",
    "Activity_Form_Badge": "Insignia del Evento (Opcional)",
    "Activity_Form_Wechat": "Grupo de WeChat del Evento",
    "Activity_Form_Wechat_Des": "El código QR se mostrará después del registro exitoso del participante.",
    "Activity_Form_Wechat_Account": "Cuando el código QR no funcione, los participantes pueden encontrarte a través del ID de WeChat",
    "Activity_Form_Badge_Des": "Cuando un participante del evento se registre, recibirá automáticamente una insignia al final del evento.",
    "Activity_Form_Badge_Select": "Configurar una insignia POAP para los asistentes",
    "Activity_Form_wechat_Select": "Seleccionar Imagen",
    "Activity_Form_Ending_Time_Error": "La hora de finalización debe ser posterior a la hora de inicio",
    "Activity_Detail_Btn_Modify": "Editar",
    "Activity_Detail_site_Occupied": "El lugar seleccionado ya está ocupado durante el intervalo de tiempo elegido. Por favor, elige otro lugar o un horario diferente para el evento.",
    "Activity_Detail_Btn_Canceled": "Cancelado",
    "Activity_Detail_Btn_unjoin": "Cancelar",
    "Activity_Detail_Btn_Cancel": "Cancelar Evento",
    "Activity_Detail_Btn_Checkin": "Registro",
    "Activity_Detail_Btn_Attend": "Confirmar asistencia",
    "Activity_Detail_Btn_Joined": "Unido",
    "Activity_Detail_Btn_End": "El evento ha terminado",
    "Activity_Detail_Btn_has_Cancel": "El evento ha sido cancelado",
    "Activity_Detail_Btn_add_Calender": "Agregar al calendario",
    "Activity_Detail_Badge": "La inscripción al evento, al completarse, será recompensada con POAP*1",
    "Activity_Detail_Guest": "Invitado",
    "Activity_Detail_Offline_location": "Ubicación (Opcional)",
    "Activity_Detail_Offline_location_Custom": "Seleccionar la ubicación",
    "Activity_Detail_Offline_Tg": "Grupo de Telegram del Evento (Opcional)",
    "Activity_Detail_Offline_Tg_des": "El enlace del grupo se mostrará después de que los participantes hayan sido confirmados.",
    "Activity_Detail_Online_address": "Dirección en línea",
    "Activity_Detail_Btn_AttendOnline": "Asistir en línea",
    "Activity_Detail_min_participants_Alert": slotLang("Cuando el número de participantes sea menor a {1}, la actividad podría ser cancelada. El registro está disponible media hora antes del inicio del evento."),
    "Activity_quantity_Input": "personalizado",
    "Activity_Detail_Expired": "Pasado",
    "Activity_Detail_Created": "En curso",
    "Activity_Detail_Wechat": "Unirse al grupo de WeChat",
    "Activity_Detail_Account": "O agrega la cuenta de WeChat: ",
    "Activity_Calendar_Page_Time": "Hora",
    "Activity_Calendar_Page_Name": "Evento",
    "Activity_Host_Check_And_Send": "Registrarse y Enviar POAP",
    "Activity_Host_Send": "Enviar POAP",
    "Activity_Unjoin_Confirm_title": "¿Estás seguro de que quieres abandonar este evento?",
    "New_Year_1": "Lanza tus deseos de Año Nuevo en una insignia digital.",
    "New_Year_2": "Motivo de emisión:",
    "New_Year_3": "Te envío una insignia, escanea <br> el código para obtenerla",
    "Save_Card": "Guardar imagen",
    "Card_Event_Success_1": "Escanea el código",
    "Card_Event_Success_2": "y asiste al evento",
    "Group_invite_title": "Invitar",
    "Group_invite_badge_name": slotLang("{groupName} {role}"),
    "Group_invite_message": "Mensaje de Invitación",
    "Group_invite_receiver": "Receptor(es)",
    "Group_invite_Nondesignated": "No designado",
    "Group_invite_Designated": "Designado",
    "Group_invite_default_reason": slotLang("Te invito a convertirte en {name} {role}"),
    "Group_invite_detail_benefits": "Beneficios",
    "Group_invite_detail_benefits_des": slotLang("Automáticamente te convertirás en miembro de la organización {n}."),
    "Group_invite_share": "#1 te ha enviado una insignia NFT: #2. ¡Ve a obtenerla! \n #3 \n Se recomienda usar el navegador MetaMask o imToken para acceder al sitio web.",
    "Group_regist_confirm": "Crear un grupo",
    "Group_regist_owner": "Propietario del grupo",
    "Group_regist_confirm_dialog": "Este grupo se creará con el dominio: ",
    "Group_regist_des": "Las insignias se envían a los miembros\n en nombre de la organización",
    "Group_regist_title": "¡Configura un dominio Social Layer único para tu grupo!",
    "Group_setting_title": "Configuraciones",
    "Group_setting_dissolve": "Congelar el Grupo",
    "Group_freeze_dialog_title": "¿Estás seguro de que quieres congelar este grupo?",
    "Group_freeze_dialog_des": "Una vez congelado, toda la información en este grupo ya no se mostrará y no podrá ser recuperada. Los registros de premiación de insignias aún podrán recuperarse.",
    "Group_freeze_Dialog_confirm": "Congelar",
    "Group_freeze_Dialog_cancel": "Cancelar",
    "Group_relation_ship_member": "Miembro",
    "Group_relation_ship_owner": "Propietario",
    "Follow_detail_followed": "Seguidores",
    "Follow_detail_following": "Siguiendo",
    "Follow_detail_groups": "Grupos",
    "Follow_detail_btn_mint": "Enviar una insignia para tu grupo",
    "Group_detail_tabs_member": "Miembros",
    "Group_detail_tabs_Event": "Eventos",
    "Group_detail_tabs_Invite": "Invitados",
    "Group_detail_Join_Time": "Unido",
    "Relation_Ship_Action_Follow": "Seguir",
    "Relation_Ship_Action_Followed": "Seguido",
    "Relation_Ship_Action_Following": "Siguiendo",
    "Relation_Ship_Action_Join": "Unirse",
    "Relation_Ship_Action_Joined": "Unido",
    "Relation_Ship_Action_Leave": "Dejar grupo",
    "Relation_Ship_Action_Unfollow": "Dejar de seguir",
    "Empty_Text": "Aún no hay datos~",
    "Empty_No_Badge": "Aún no hay insignias~",
    "Empty_No_Present": "Aún no se ha enviado~",
    "Empty_No_Group": "Aún no hay grupos~",
    "Empty_No_Invite": "Aún no hay invitaciones~",
    "Search_Tab_Domain": "Dominio",
    "Search_Tab_Badge": "Insignia",
    "Search_Tab_Tag": "Etiqueta",
    "Search_Tab_Event": "Evento",
    "Badgebook_Dialog_Choose_Badgebook": "Elegir del libro de insignias",
    "Badgebook_Dialog_Choose_Badge": "Elegir entre las que creaste",
    "Badgebook_Dialog_Choose_Group_Badge": slotLang("Elegir entre las creadas en 「{groupname}」"),
    "Badgebook_Dialog_Choose_Draft": "Elegir entre los borradores",
    "Badgebook_Dialog_Cetate_Badge": "Crear una nueva insignia",
    "Badgebook_Dialog_Recognition_Badge": "Insignia Básica",
    "Badgebook_Dialog_Recognition_Des": "Insignia básica, evaluación de otros",
    "Badgebook_Dialog_Points": "Puntos",
    "Badgebook_Dialog_Points_Des": "Crear sistemas de puntos en grupos",
    "Badgebook_Dialog_Privacy": "Insignia Privada",
    "Badgebook_Dialog_Privacy_Des": "Solo los receptores pueden ver la insignia",
    "Badgebook_Dialog_NFT_Pass": "NFT Pass",
    "Badgebook_Dialog_NFT_Pass_Des": "Dado por los grupos a la persona",
    "Badgebook_Dialog_Gift": "Tarjeta de Regalo",
    "Badgebook_Dialog_Gift_Des": "Enviar una insignia con beneficios",
    "Dialog_Public_Image_Title": "Elige una imagen para la insignia",
    "Dialog_Public_Image_UploadBtn": "Subir una imagen",
    "Dialog_Public_Image_UploadBtn_Des": "JPG o PNG. Tamaño máximo de 800K",
    "Dialog_Public_Image_List_Title": "Público",
    "Cropper_Dialog_Title": "Editar imagen",
    "Cropper_Dialog_Btn": "Aplicar",
    "Presend_Qrcode_Badge": "insignia",
    "Presend_Qrcode_Des": slotLang("{1} te envió un {2}."),
    "Presend_Qrcode_Recommended": "Recomendado",
    "Presend_Qrcode_Scan": "Escanea el Código QR",
    "Presend_Qrcode_Limit": slotLang("Limitado a {1} persona"),
    "Presend_Qrcode_Time": slotLang("Tiempo de expiración: {1}"),
    "Presend_Qrcode_Time_2": slotLang("Hora de inicio: {1}"),
    "Presend_Qrcode_Expired": "La insignia ya no es válida",
    "Presend_Qrcode_Regen": "Regenerar el código QR",
    "Home_Page_New_Title": "Crear una insignia",
    "Home_Page_New_Des": "Únete ahora para comenzar a crear insignias, describir tus logros y otorgarlas a personas merecedoras.",
    "Home_Page_New_Btn": "Crea tu insignia",
    "Badgelet_List_Title": "Coleccionadas",
    "Badgelet_List_Unit": "Insignias",
    "Created_List_Title": "Creadas",
    "Dialog_Copy_Btn": "Ok",
    "Dialog_Copy_Title": "¡Copiado con éxito!",
    "Dialog_Copy_Message": "Comparte y abre el enlace en un navegador.",
    "Profile_Bio_More": "Más…",
    "Profile_Bio_Less": "Menos",
    "Profile_Edit_Title": "Editar Perfil",
    "Profile_Edit_Avatar": "Avatar",
    "Profile_Edit_Ncikname": "Apodo",
    "Profile_Edit_Bio": "Biografía",
    "Profile_Edit_Bio_Placeholder": "Establece una biografía",
    "Profile_Edit_Location": "Ubicación",
    "Profile_Edit_Social_Media": "Redes sociales",
    "Profile_Edit_Social_Media_Edit": "Editar",
    "Profile_Edit_Social_Media_Edit_Dialog_Title": "Tu ",
    "Profile_Edit_Social_Confirm": "Confirmar",
    "Profile_Edit_Save": "Guardar",
    "Profile_Edit_Leave": "Salir",
    "Profile_Edit_Cancel": "Cancelar",
    "Profile_Edit_Leave_Dialog_Title": "¿Estás seguro de que quieres salir?",
    "Profile_Edit_Leave_Dialog_Des": "No has guardado tus configuraciones aún.",
    "Group_Member_Manage_Dialog_Title": "Gestión de Miembros",
    "Group_Manager_Setting": "Configuración del Administrador",
    "Group_Member_Manage_Dialog_Confirm_Btn": "Eliminar del grupo",
    "Group_Member_Manage_Dialog_Confirm_Dialog_des": slotLang("¿Estás seguro de que quieres eliminar a {1} del grupo?"),
    "Group_Member_Manage_Dialog_Confirm_Dialog_Confirm": "Eliminar",
    "Group_Member_Manage_Dialog_Confirm_Dialog_Cancel": "Cancelar",
    "Create_Point_Title": "Crear un punto",
    "Create_Point_Symbol": "Símbolo",
    "Create_Point_Image": "Imagen",
    "Create_Point_Name": "Nombre",
    "Create_Point_Name_Placeholder": "Rellena el nombre, ej. Puntos de Conocimiento",
    "Create_Point_Symbol_Placeholder": "Rellena el símbolo, ej. PT",
    "Create_Point_Des": "Descripción (Opcional)",
    "Create_NFT_Title": "Crear un NFT Pass",
    "Create_NFT_Image": "Imagen",
    "Create_NFT_Name": "Nombre",
    "Create_NFT_Name_Placeholder": "Rellena el nombre",
    "Create_NFT_Name_Domain": "Dominio",
    "Create_NFT_Name_Des": "Descripción (Opcional)",
    "Create_Nft_success": "Creación Exitosa",
    "Create_Nft_success_des": "Tu NFT Pass ha sido creado",
    "Issue_Nft_Title": "Enviar NFT Pass",
    "Issue_Nft_Start": "Fecha de inicio",
    "Issue_Nft_End": "Fecha de vencimiento",
    "NFT_Detail_title": "Detalles del NFT Pass",
    "NFT_Detail_checkin_title": "Registros de Check-In",
    "NFT_Detail_Des": "Descripción",
    "NFT_Detail_Check": "Check-In",
    "NFT_Detail_use": "Usar el NFT Pass",
    "NFT_Detail_show_record_btn": "Ver Registros",
    "NFT_Detail_Expiration": "Fecha de Expiración",
    "NFT_Detail_Unavailable": "No está en el período de validez",
    "Point_Detail_Title": "Detalles del Punto",
    "Create_Point_success": "Creación Exitosa",
    "Create_Point_success_des": "Tus puntos han sido creados",
    "Issue_Point_Title": "Enviar Puntos",
    "Issue_Point_Point": "Puntos",
    "Dialog_Check_In_Title": "Check-In",
    "Create_Privacy_Title": "Crear Insignia Privada",
    "Create_Privacy_Tips": "Solo tú y el propietario pueden ver la insignia, los demás solo pueden ver al creador y al propietario de la insignia.",
    "Create_Gift_Title": "Crear una Tarjeta de Regalo",
    "Create_Gift_Benefits": "Beneficios",
    "Selector_issue_type_gift": "Enviar la Tarjeta de Regalo",
    "Selector_issue_type_gift_times": "Cantidad de beneficios",
    "Create_gift_success": "Creación Exitosa",
    "Create_gift_success_des": "Tu Tarjeta de Regalo ha sido creada",
    "Gift_detail_check_btn": "Verificar",
    "Gift_Detail_use": "Usar",
    "Gift_Detail_amount": "Veces Restantes",
    "Gift_Detail_check_remain": slotLang("¡Verificado! Restan {1} veces"),
    "Gift_Checked_Title": "Verificación Exitosa",
    "Gift_Checked_Des": "Tus beneficios han sido usados.",
    "Gift_Checked_Btn": slotLang("Usar nuevamente (Restan {1} veces)"),
    "Gift_Checked_show_remain": slotLang("Restan {1} veces"),
    "Gift_Checked_show_receiver": "receptor",
    "Gift_Checked_show_last_consume": "último consumo: ",
    "Create_Badge_Success_Title": "Creación Exitosa",
    "Create_Badge_Success_Des": "Tu insignia ha sido creada",
    "Selector_issue_type_badge": "Enviar la insignia",
    "Selector_issue_type_amount": "Cantidad de insignias",
    "Meeting_Zoom_Title": "Reunión en Zoom",
    "Meeting_Google_Title": "Reunión en Google",
    "Meeting_Tencent_Title": "Reunión en Tencent",
    "Meeting_Others_Title": "Reunión en línea",
    "Event_Card_Apply_Btn": "RSVP",
    "Event_Card_Applied": "Registrado",
    "Login_Phone_Title": "Iniciar sesión con teléfono",
    "Login_Phone_alert": "Introduce tu número de teléfono para recibir un código de verificación",
    "Login_Phone_continue": "Siguiente",
    "Login_Phone_Placeholder": "Tu número de teléfono",
    "Login_Phone_input_Code_title": "Código de verificación",
    "Login_Phone_input_Code_des": slotLang("Introduce el código que te enviamos a {phone} para completar la configuración de tu cuenta."),
    "Event_Site_Title": "Lugares",
    "Event_Site_Remove": "Eliminar",
    "Event_Site_Location_title": "Ubicación del lugar",
    "Setting_Title": "Configuración de la Organización",
    "Setting_Event_site": "Lugares",
    "Setting_Dashboard": "Panel",
    "Setting_Participants": "Número de participantes",
    "Setting_Hosts": "Número de anfitriones",
    "Setting_Events": "Eventos",
    "Setting_Badge": "Insignias recibidas",
    "Setting_Banner": "Banner",
    "Setting_Location": "Ubicación predeterminada",
    "Setting_Banner_Link": "Enlace (Opcional)",
    "Setting_Permission": "Permiso del evento",
    "Permission": "Permiso",
    "Event_Today": "Hoy",
    "Event_Tomorrow": "Mañana",
    "Event_Label_All": "Todos",
    "UserAction_Bind_Email": "Vincular Correo Electrónico",
    "Bind_Email_Title": "Vincular Correo Electrónico",
    "Bind_Email_Des": "Por favor, ingresa tu dirección de correo electrónico para que puedas iniciar sesión y recibir notificaciones importantes por correo.",
    "Bind_Email_Skip": "Saltar",
    "Profile_Tab_Lens": "Lente",
    "Profile_Tab_Asset": "Activo",
    "Profile_Tab_Token": "Token",
    "Profile_Asset_Amount": "Cantidad",
    "BadgeDialog_Label_Owner": "Propietario",
    "BadgeDialog_Label_Sender": "Remitente",
    "BadgeDialog_Label_action_Burn": "Quemar",
    "BadgeDialog_Label_action_Revoke": "Revocar",
    "Follow_detail_Recently": "Recientemente",
    "Group_detail_tabs_Vote": "Votos",
    "Group_detail_tabs_Group": "Junta",
    "Presend_Qrcode_isGroup": "Grupo ",
    "Create_Point_Transferable": "Transferible",
    "Create_Point_Transferable_Tips": "Permite al propietario transferir el punto y sus beneficios a otros.",
    "Dialog_Transfer_Title": "Transferir a",
    "Dialog_Transfer_Confirm": "Transferir",
    "Detail_Transfer_Accept_Title_Gift": "Has recibido una Tarjeta de Regalo",
    "Detail_Transfer_Accept_Title_Nft": "Has recibido un NFT Pass",
    "Detail_Transfer_Accept_Title_Point": "Has recibido un Punto NFT",
    "Detail_Transfer_Accept_Confirm": "Ok",
    "Detail_Transfer_Accept_From": "De",
    "Dialog_Revoke_Title": "Seleccionar propietarios",
    "Dialog_Revoke_Confirm_Title": "¿Estás seguro de que quieres revocar la insignia?",
    "Dialog_Revoke_Des": "Una vez revocada, la insignia y los beneficios serán inválidos, pero el registro se puede encontrar en la cadena.",
    "Dialog_Revoke_Confirm": "Revocar",
    "Dialog_burn_Title": "¿Estás seguro de que quieres quemar esta insignia?",
    "Dialog_burn_Confirm_des": "Una vez quemada, la insignia y los beneficios serán inválidos, pero el registro se puede encontrar en la cadena.",
    "Vote_Create_Page_Title": "Crear votación",
    "Vote_Create_Title": "Nombre de la votación",
    "Vote_Create_Title_Placeholder": "Introduce el título",
    "Vote_Create_Des": "Descripción (Opcional)",
    "Vote_Create_Content_Placeholder": "Introduce la descripción (Opcional)",
    "Vote_Create_Option_Input_Title": "Opción",
    "Vote_Create_Multiple_Choice": "Elección múltiple",
    "Vote_Create_Show_Voter": "Mostrar votantes",
    "Vote_Create_Auth": "Autoridad de votación",
    "Vote_Create_Auth_Member": "Todos los miembros de este grupo",
    "Vote_Create_Auth_Badge": "Propietarios de insignias",
    "Vote_Create_Auth_Badge_count": "Número de insignias usadas como peso",
    "Vote_Create_Has_Expire": "Hora de cierre para la votación",
    "Vote_Create_Has_Start": "Hora de inicio para la votación",
    "Vote_Create_Create_Btn": "Crear",
    "Vote_Create_Voters": "Votantes",
    "Vote_Confirm_Dialog_Title": slotLang("¿Estás seguro de votar en 「{1}」?"),
    "Vote_Confirm_Dialog_Confirm": "Votar",
    "Vote_Confirm_Dialog_Cancel": "Más tarde",
    "Vote_Confirm_Dialog_Des": "No puedes cambiarlo después de votar",
    "Vote_Confirm_Dialog_Selected": "Seleccionado:",
    "Vote_Already_Voted": "Ya has votado",
    "Vote_Close_Time": "La votación se cerrará a las ",
    "Vote_Start_Time": "La votación comenzará a las ",
    "Vote_Close_Once": "Solo puedes seleccionar una opción",
    "Vote_Eligibility_Member": "Solo los miembros de este grupo pueden votar",
    "Vote_Eligibility_Badge": slotLang("El miembro del grupo que tenga la insignia 「{1}」 puede votar"),
    "Vote_detail_edit": "Editar",
    "Vote_detail_Title": "Votación",
    "Vote_detail_Cancel": "Eliminar",
    "Vote_detail_hoster": "Iniciador de la votación:",
    "Vote_Delete_Vote_title": "¿Estás seguro de eliminar la votación?",
    "Vote_Delete_Vote_Des": "Los datos de la votación no se pueden recuperar después de la eliminación.",
    "Month_Name": [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic"
    ],
    "Day_Name": [
        "Dom",
        "Lun",
        "Mar",
        "Mié",
        "Jue",
        "Vie",
        "Sáb"
    ],
    "Days": "Días",
    "Minute": "Min",
    "Form_All_Day": "Todo el día",
    "Form_Repeat_Not": "No repetir",
    "Form_Repeat_Day": "Cada día",
    "Form_Repeat_Week": "Cada semana",
    "Form_Repeat_Month": "Cada mes",
    "Form_Marker_Title": "Crear un marcador",
    "Form_Marker_Category": "Categoría",
    "Form_Marker_Title_Label": "Título",
    "Form_Marker_Des_Label": "Descripción (Opcional)",
    "Form_Marker_Creator_Label": "Creador",
    "Form_Marker_Image_Label": "Imagen (Opcional)",
    "Form_Marker_Badge_Label": "Insignia de check-in",
    "Form_Marker_Badge_Des": "La insignia se envía automáticamente al usuario como recompensa después de hacer check-in.",
    "Form_Marker_Location": "Ubicación",
    "Form_Marker_Create_Btn": "Crear un Marcador",
    "Form_Marker_Title_Error": "El título no puede estar vacío",
    "Form_Marker_Location_Error": "La ubicación no puede estar vacía",
    "Form_Marker_Link_Label": "Enlace (Opcional)",
    "Marker_Detail_Records": "Registros",
    "Marker_Edit_Remove": "Eliminar marcador",
    "Group_Change_Owner": "Cambiar propietario",
    "Group_Role_Owner": "Propietario",
    "Group_Role_Manager": "Administrador",
    "Seedao_Role_Text": slotLang("Eres {1} en SeeDAO"),
    "Seedao_Role_Apply_Issuer": "Solicitar ser emisor",
    "Seedao_Notification": "Notificaciones",
    "Seedao_Send_Badges": "Enviar insignias",
    "Seedao_Issuer_Whitelist": "(Lista blanca de emisores)",
    "Seedao_Issuer_Manager_Whitelist": "(Lista blanca de emisores, Listas de administradores)",
    "Seedao_Request_Issuer_Dialog_Title": "¿Estás seguro de solicitar ser emisor?",
    "Seedao_Request_Issuer_Dialog_Message": "Deja un mensaje",
    "Seedao_Request_Issuer_Dialog_Apply": "Solicitar",
    "Notification_Title": "Notificaciones",
    "Seedao_Issue_Badge_Role": "Rol",
    "Seedao_Issue_Badge_Section": "Sección",
    "Seedao_Issue_Badge_Institution": "Institución emisora de SBT",
    "Send_The_Badge": "Enviar la insignia",
    "Select_Receivers": "Seleccionar receptores",
    "Badge_Amount": "Cantidad de insignias",
    "From_Domain": "Desde dominio",
    "From_Csv": "Desde CSV",
    "Issuer": "Emisor",
    "Setting": "Configuración",
    "Transfer_Owner": "Transferir propietario",
    "Add_Issuer": "Agregar Emisor",
    "Set_As_Issuer": "Establecer como Emisor",
    "Select_From_Members": "Seleccionar de los miembros",
    "Remove_Issuer": "Eliminar Emisor",
    "Send_SeeDAO_Badge": "Enviar insignia de SeeDAO",
    "Event_Tag": "Etiquetas del evento",
    "Received": "Recibido",
    "Profile_Setting": "Configuración del perfil",
    "Checkins": "Check-ins",
    "Comment": "Comentario",
    "Sign_To_Comment": "Inicia sesión para chatear",
    "Chat": "Chat",
    "Request_To_Be_Issuer": "Solicitar ser emisor",
    "Pending": "Pendiente",
    "Publish": "Publicar",
    "Reject": "Rechazar",
    "Rejected": "Rechazado",
    "Are_You_Sure_To_Publish_This_Event": "¿Estás seguro de publicar este evento?",
    "Yes": "Sí",
    "No": "No",
    "Are_You_Sure_To_Reject_This_Event": "¿Estás seguro de rechazar este evento?",
    "Ongoing": "En curso",
    "Public_Events": "Eventos públicos",
    "My_Events": "Mis eventos",
    "Publish_Request": "Solicitud de publicación",
    "Ticket_Type_Setting": "Configuración del tipo de entrada",
    "Ticket": "Entrada",
    "Name_Of_Tickets": "Nombre de las entradas",
    "Ticket_Description": "Descripción de la entrada (Opcional)",
    "Price": "Precio",
    "Receiving_Wallet_Address": "Billetera receptora",
    "Ticket_Amount": "Cantidad de entradas",
    "Qualification": "Calificación (Opcional)",
    "Tickets": "Entradas",
    "Select_A_Badge": "Seleccionar una insignia",
    "Get_A_Ticket": "RSVP",
    "External_Url": "URL externa",
    "Notifications": "Notificaciones",
    "Send_You_A_Badge": "Te envía una insignia.",
    "Go_to_Event_Page": "Ir a la página del evento",
    "Back_To_Profile_Page": "Volver a la página del perfil",
    "Back_To_Group_Page": "Volver a la página del grupo",
    "Back_To_Event_Home": "Volver a la página principal de eventos",
    "Event_Notes": "Notas del evento",
    "Event_Notes_": "Nota del evento (Mostrar después de confirmar asistencia)",
    "Input_Notes": "Introduce la nota del evento",
    "Featured": "Destacado",
    "Events_Of_Popup_Cities": "Ciudades emergentes",
    "Communities": "Comunidades",
    "See_All_Communities": "Ver todas las comunidades",
    "See_All_Popup_Cities_Events": "Ver todos los eventos de Ciudades emergentes",
    "Go_to": "Ir a",
    "My_Badges": "Mis insignias",
    "My_Groups": "Mis grupos",
    "About_Us": "Sobre nosotros",
    "What_s_badge": "¿Qué es una insignia?",
    "Development_Doc": "Documento de desarrollo",
    "Attended": "Registrado",
    "Pending_Requests": "Solicitudes pendientes",
    "My_Communities": "Mis comunidades",
    "My_Subscriptions": "Mis suscripciones",
    "Timezone": "Zona horaria",
    "Select_A_Timezone": "Seleccionar una zona horaria",
    "Calendar_View": "Vista de calendario",
    "Schedule_View": "Vista de horario",
    "Timeline_View": "Vista de línea de tiempo",
    "Download_the_list_of_all_participants": "Descargar la lista de todos los participantes",
    "More_Settings": "Más configuraciones",
    "Private": "Privado",
    "Go_To_Event_Detail": "Detalles del evento",
    "Create_Next_Event": "Agregar otro evento"
}

const langEs: LangConfig = {...langEN, ...langEsData}
export default langEs
